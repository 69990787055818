import React from "react";
import { Link } from "gatsby";

import { Container, Details, Summary } from "./styles";

const InformationPrompt: React.FunctionComponent = () => {
  const summaryRef = React.useRef<HTMLElement | null>(null);

  const handleSummaryLinkClick = (): void => {
    summaryRef.current?.click();
  };

  return (
    <Container>
      <Details>
        <Summary ref={summaryRef}>
          A new way of looking at the web.{" "}
          <a onClick={handleSummaryLinkClick}>Learn more</a>
        </Summary>

        <p>
          Textise is an internet tool that removes everything from a web page
          except for its text. In practice, this means that images, forms,
          scripts, adverts, they all go, leaving plain text.
        </p>

        <h3>How to use this page</h3>

        <ol>
          <li>
            Type or paste the URL of a web page into the box below and click
            &quot;Textise&quot;. A text only version of the web page will be
            displayed.
          </li>

          <li>
            Type a search term into the box, select a search engine from the
            drop-down list, and click &quot;Search&quot;. You will be taken to a
            text only version of the search results.
          </li>
        </ol>

        <p>
          Textise will also display search forms on selected sites (for example
          bbc.co.uk, amazon.com) if enabled on the{" "}
          <Link to="/options">Options page</Link>.
        </p>
      </Details>
    </Container>
  );
};

export default InformationPrompt;
