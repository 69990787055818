import styled from "styled-components";

export const Container = styled.div`
  width: 70%;
  margin: 2rem auto;
`;

export const Form = styled.form``;

export const Input = styled.input`
  display: block;
  width: 100%;
  /* box-shadow: 0 0 10px 0 rgba(0,0,0, 0.15); */
  box-shadow: rgba(175, 172, 189, 0.3) 0px 0px 15px 0px;
  outline: none;
  border: none;
  border-radius: 1.2rem;
  font-size: 1.1rem;
  padding: 0.2rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Button = styled.button`
  margin: 0 0.5rem;
`;
