import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Logo from "../components/Logo";
import SearchBox from "../components/SearchBox";
import InformationPrompt from "../components/InformationPrompt";
import Nav from "../components/Nav";
import { Rule } from "../components/Rule";

export default function Index(): JSX.Element {
  return (
    <Layout>
      <SEO />
      <Logo />
      <Nav />
      <SearchBox />
      <Rule />
      <InformationPrompt />
    </Layout>
  );
}
