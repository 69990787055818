// public
import React from "react";

// local
import { Select } from "../Input";
import { Container, Form, Input, ButtonGroup, Button } from "./styles";

const searches: Record<string, string> = {
  Google: "https://google.com/search?q=*****",
  Bing: "https://bing.com/search?q=*****",
};

const SearchBox: React.FunctionComponent = () => {
  const [input, setInput] = React.useState("");
  const [search, setSearch] = React.useState("Google");

  const handleFormSubmit = (
    event: React.FormEvent,
    trySearch = false
  ): void => {
    event.preventDefault();

    const val = input.trim();
    if (!val) return;

    const willSearch = !val.includes(".") || trySearch;
    const targetUrl = new URL(window.location.href);
    targetUrl.pathname = "/showText.aspx";

    if (willSearch) {
      const parsedVal = new URL(searches[search].replace("*****", val));
      targetUrl.searchParams.set("strURL", parsedVal.href);
    } else {
      targetUrl.searchParams.set("strURL", val);
    }

    window.location.href = targetUrl.href;
  };

  return (
    <Container>
      <Form onSubmit={(e): void => handleFormSubmit(e)}>
        <Input
          value={input}
          onChange={(e): void => setInput(e.target.value)}
          placeholder="URL or search term"
        />
        <ButtonGroup>
          <Button type="button" onClick={(e): void => handleFormSubmit(e)}>
            Textise
          </Button>
          <Button
            type="button"
            onClick={(e): void => handleFormSubmit(e, true)}
          >
            Search
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <label>
            For searches, use:{" "}
            <Select
              value={search}
              onChange={(e): void => setSearch(e.target.value)}
            >
              {Object.keys(searches).map((key, i) => (
                <option key={`search-${i}`} value={key}>
                  {key}
                </option>
              ))}
            </Select>
          </label>
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default SearchBox;
