import styled from "styled-components";

export const Container = styled.div``;

export const Details = styled.details``;

export const Summary = styled.summary`
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
`;
